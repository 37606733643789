import Head from "next/head";
import Link from "next/link";
import PageLayout, { siteTitle } from "../components/PageLayout";

function Home({ manufacturers }) {
  return (
    <PageLayout home>
      <Head>
        <title>{siteTitle}</title>
      </Head>
      <section>
        <h1>LEX CMS WELCOME</h1>
      </section>
    </PageLayout>
  );
}

export default Home;
